import React, { useState, useEffect } from "react"
import { navigate, graphql } from "gatsby"
import { Formik } from "formik"
import * as Yup from "yup"
import Container from "@components/styled/Container"
import Layout from "@components/layout"
import SEO from "@components/seo"
import TextInput from "@components/TextInput"
import SelectInput from "@components/Select"
import moment from "moment"
import { SingleDatePicker } from "react-dates"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import Columns from "@components/styled/Columns"
import Column from "@components/styled/Column"
import get from "lodash/get"
import { base } from "@helpers/airtable"
import { mapOptions } from "@helpers/data"
import Back from "@components/Back"
import {
  FormHead,
  Form,
  FormSection,
  FieldGroup,
  FormActions,
  Textarea
} from "@components/Form"
import { authCheckRedirect } from "@helpers/auth"

const initialFormState = {
  article_name: "",
  article_type: "",
  publisher: "",
  author: "",
  potential_reach: 0,
  published_date: moment(),
  URL: "",
  comments: ""
}

const validationSchema = Yup.object().shape({
  article_name: Yup.string().required("This is required"),
  article_type: Yup.string().required("This is required")
})

const AddArticlePage = ({ user, data, ...props }) => {
  const [submissionError, setSubmissionError] = useState(false)

  const [calendarFocus, setCalendarFocus] = useState({
    published: false
  })

  useEffect(() => {
    authCheckRedirect()
  }, [])

  const articleTypeOptions = mapOptions(
    data.articleTypeOptions.edges,
    "article_type"
  )

  const onCalendarFocusChange = (e, type) => {
    setCalendarFocus({ ...calendarFocus, [type]: e.focused })
  }

  const createArticle = async values => {
    const article = {
      fields: {
        "Article Name": values.article_name,
        "Article Type": [values.article_type],
        Publisher: values.publisher,
        Author: values.author,
        "Potential Reach": values.potential_reach,
        "Published Date": values.published_date.format("YYYY-MM-DD"),
        URL: values.URL,
        Comments: values.comments
      }
    }

    return base("Articles").create([article])
  }

  const handleSubmit = async (values, actions) => {
    try {
      await createArticle(values)
      actions.setSubmitting(false)
      navigate("/view-articles")
    } catch (e) {
      setSubmissionError(true)
      actions.setSubmitting(false)
      console.log(e, "error")
    }
  }

  return (
    <Layout uri={props.uri}>
      <SEO title="Add article" />
      <Container pt={[7]}>
        <section>
          <Back to="/view-articles" />
          <FormHead title="Add an article" />

          <Formik
            initialValues={initialFormState}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              handleChange,
              handleBlur,
              submitForm,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              resetForm,
              values,
              errors,
              touched
            }) => (
              <Form>
                <FormSection title="Basics">
                  <Columns>
                    <Column>
                      <FieldGroup
                        required
                        name="article_name"
                        label="Article name"
                      >
                        <TextInput
                          name="article_name"
                          value={values.article_name}
                          onChange={handleChange}
                          error={
                            get(touched, "article_name") &&
                            get(errors, "article_name") &&
                            get(errors, "article_name")
                          }
                          onBlur={handleBlur}
                        />
                      </FieldGroup>
                    </Column>

                    <Column width={[1, 0.5]}>
                      <FieldGroup
                        required
                        name="article_type"
                        label="Article type"
                      >
                        <SelectInput
                          name="article_type"
                          value={values.article_type}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          error={
                            get(touched, "article_type") &&
                            get(errors, "article_type") &&
                            get(errors, "article_type")
                          }
                          options={articleTypeOptions}
                        />
                      </FieldGroup>
                    </Column>
                  </Columns>
                </FormSection>

                <FormSection title="Details">
                  <Columns>
                    <Column>
                      <FieldGroup name="publisher" label="Publisher">
                        <TextInput
                          name="publisher"
                          value={values.publisher}
                          onChange={handleChange}
                          error={
                            get(touched, "publisher") &&
                            get(errors, "publisher") &&
                            get(errors, "publisher")
                          }
                          onBlur={handleBlur}
                        />
                      </FieldGroup>
                    </Column>
                    <Column>
                      <FieldGroup name="author" label="Author">
                        <TextInput
                          name="author"
                          value={values.author}
                          onChange={handleChange}
                          error={
                            get(touched, "author") &&
                            get(errors, "author") &&
                            get(errors, "author")
                          }
                          onBlur={handleBlur}
                        />
                      </FieldGroup>
                    </Column>
                    <Column>
                      <FieldGroup
                        name="publishedDate_input"
                        label="Published date"
                      >
                        <SingleDatePicker
                          id="publishedDate_input"
                          date={values.published_date}
                          focused={calendarFocus.published}
                          onDateChange={e => setFieldValue("published_date", e)}
                          onFocusChange={e =>
                            onCalendarFocusChange(e, "published")
                          }
                          numberOfMonths={1}
                        />
                      </FieldGroup>
                    </Column>
                  </Columns>
                </FormSection>

                <FormSection title="Stats">
                  <Columns>
                    <Column width={[1, 0.25]}>
                      <FieldGroup
                        name="potential_reach"
                        label="Potential reach"
                      >
                        <TextInput
                          type="number"
                          name="potential_reach"
                          value={values.potential_reach}
                          onChange={handleChange}
                        />
                      </FieldGroup>
                    </Column>
                  </Columns>
                </FormSection>

                <FormSection>
                  <FieldGroup name="comments" label="Comments">
                    <Textarea
                      name="comments"
                      id="comments"
                      value={values.comments}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FieldGroup>

                  <FieldGroup name="URL" label="URL">
                    <TextInput
                      name="URL"
                      value={values.URL}
                      onChange={handleChange}
                    />
                  </FieldGroup>
                </FormSection>

                <FormActions
                  onSubmitClick={submitForm}
                  onClearClick={() => resetForm(initialFormState)}
                  submitDisabled={isSubmitting}
                  error={submissionError}
                />
              </Form>
            )}
          </Formik>
        </section>
      </Container>
    </Layout>
  )
}

export default AddArticlePage

export const query = graphql`
  query {
    articleTypeOptions: allAirtable(
      filter: { table: { eq: "DL_Article_Type" } }
    ) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }
  }
`
